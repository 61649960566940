/*
 CSS for the main interaction
*/
.tabset > input[type="radio"] {
    position: absolute;
    left: -200vw;
  }
  
  .tabset .tab-panel {
    display: none;
  }
  
  .tabset > input:first-child:checked ~ .tab-panels > .tab-panel:first-child,
  .tabset > input:nth-child(3):checked ~ .tab-panels > .tab-panel:nth-child(2),
  .tabset > input:nth-child(5):checked ~ .tab-panels > .tab-panel:nth-child(3),
  .tabset > input:nth-child(7):checked ~ .tab-panels > .tab-panel:nth-child(4),
  .tabset > input:nth-child(9):checked ~ .tab-panels > .tab-panel:nth-child(5),
  .tabset > input:nth-child(11):checked ~ .tab-panels > .tab-panel:nth-child(6) {
    display: block;
  }
  
  /*
   Styling
  */
  
  
  .tabset > label {
    position: relative;
    display: inline-block;
    padding: 15px 15px 25px;
    border: 1px solid transparent;
    border-bottom: 0;
    cursor: pointer;
    font-weight: 600;
  }
  
  .tabset > label::after {
    content: "";
    position: absolute;
    left: 15px;
    bottom: 10px;
    width: 22px;
    height: 4px;
    background: #ffffff;
  }
  
  .tabset > label:hover,
  .tabset > input:focus + label {
    color: rgb(255, 255, 255);
  }
  
  .tabset > label:hover::after,
  .tabset > input:focus + label::after,
  .tabset > input:checked + label::after {
    background: #ffffff;
  }
  
  .tabset > input:checked + label {
    border-color: #ffffff;
    border-bottom: 1px solid #ffffff;
    margin-bottom: -1px;
  }
  
  .tab-panel {   
    border-top: 1px solid rgb(255, 255, 255);
  }
  
  /*
   Demo purposes only
  */
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  
  .bodyTap {
    
    h1{
        color: #16bfee;
        font-weight: 100;
        font-size: 28px;
    }
  }
  
  .tabset {
    max-width: 70em;
  }
  @media screen and (max-width: 767px) {
    .bodyTap{
      h1{
        font-size: 16px !important;
      }
    }
  }