
.loading {
  margin: 30px auto;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: red url('https://cdn.dribbble.com/users/1056629/screenshots/3220439/unicorn.gif') no-repeat -50px center;
  background-size: cover;
}

.loading2 {
  margin: 30px auto;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: transparent url('https://derpicdn.net/img/2017/6/25/1471445/full.gif') no-repeat center center;
  background-size: cover;
}