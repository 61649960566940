.map-footer{
  padding-bottom: 30px;
}
footer {
  background-color: black;
  font-family: "Lato", sans-serif;
  padding: 85px 0 0 0;
  margin-bottom: -80px !important;
}

.footer-inner {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 120px;
  max-width: 1170px;
  position: relative;
}

.footer-item {
  float: left;
  margin: 0 7.2% 0 0;
}

.footer-item:nth-of-type(4) {
  display: none;
}

.footer-item:nth-of-type(7) {
  float: right;
  margin-right: 0;
}

.footer-button {
  color: white;
  position: relative;
  font-weight: 400;
  font-size: 16px;
  transition: 1s;
  transition-delay: .2s;
  padding: 14px;
}

.footer-button:after {
  content: "";
  position: absolute;
  top: 45px;
  right: 13px;
  background-color: white;
  height: 1px;
  width: 86px;
  transition: .6s;
}

.footer-button:hover {
  color: black;
  background-color: white;
}

.footer-button:hover:after {
  width: 112px;
  right: 0px;
}
.textContact{
  h1 {
    font-weight: 900;
    color: white;
    font-size: 24px;
    letter-spacing: 2px;
    margin: 0;
    padding-bottom: 10px;
  }
  
  h2 {
    font-weight: 300;
    line-height: 1.8;
    font-size: 13px;
    color: #d1d1d1;
    letter-spacing: 0.03em;
    padding: 15px 0 0 0;
  }
  

  
  h3 {
    font-weight: 400;
    font-size: 13px;
    color: white;
    margin: 0;
    padding-bottom: 9px;
    letter-spacing: 0.03em;
  }
  
  h3.desktop {
    padding-top: 30px;
  }
  ul {
  line-height: 1.8;
  list-style-type: none;
  padding: 0;
}

li {
  font-weight: 300;
  font-size: 13px;
  color: #d1d1d1;
  letter-spacing: 0.03em;
}

p {
  font-weight: 300;
  font-size: 13px;
  padding: 0;
  line-height: 1.8;
  letter-spacing: 0.03em;
}

a {
  text-decoration: none;
  color: #d1d1d1;
}

a:hover {
  color: white;
}

}
.color {
  color: white;
  font-weight: 400;
}




.desktop {
  display: auto;
}

.footer-inner:after {
  font-weight: 300;
  letter-spacing: 0.03em;
  font-size: 13px;
  color: #d1d1d1;
  position: absolute;
  top: 260px;
  clear: both;
  display: block;
}

@media (max-width: 1024px) {

footer {
  padding: 50px 0 70px 0 !important;
}

.footer-inner {
  border-bottom: 1px solid #333;
  padding-bottom: 490px;
  margin-bottom: 50px;
}

.footer-item {
  margin: 0 0 42px 0;
  width: 50%;
}

.footer-item:nth-of-type(1) {
  border-bottom: 1px solid #333;
  padding-bottom: 32px;
  float: none;
  width: 100%;
}

.footer-item:nth-of-type(2) {
  clear: both;
}

.footer-item:nth-of-type(4) {
  clear: both;
  display: block;
}

.footer-item:nth-of-type(6) {
  clear: both;
}

.footer-item:nth-of-type(7) {
  clear: both;
  float: left;
  margin: 20px 0 0 -13px;
}

.desktop {
  display: none;
}

.footer-inner:after {
  top: 730px;
}
}


/* ranbow */
.toppad {
  text-align: center;
  /* background-color: rgba(255, 255, 255, 0.952); */
  color: #ffffff !important;
  padding-top: 20px;
  padding-bottom: 20px;
  
}

.wrapper { 

  left:0;
  right: 0;
  top: 0;
  bottom: 0;
background: linear-gradient(124deg, #1de840, #1ddde8, #2b1de8, #dd00f3, #dd00f3);
background-size: 1800% 1800%;

-webkit-animation: rainbow 18s ease infinite;
-z-animation: rainbow 18s ease infinite;
-o-animation: rainbow 18s ease infinite;
  animation: rainbow 18s ease infinite;}

@-webkit-keyframes rainbow {
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}
@-moz-keyframes rainbow {
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}
@-o-keyframes rainbow {
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}
@keyframes rainbow { 
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}