// *{
//   margin: 0;
//   padding: 0;
//   box-sizing: border-box;
// }

.body{
   min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.about-section{
  background: url("./about.jpg") no-repeat left;
  background-size: 55%;
  background-color:  #1e1f26;
  overflow: hidden;
  padding: 100px 0;
  
}

.inner-container{
  width: 55%;
  float: right;
  background-color:  #1e1f26;
  padding: 150px;
}

.inner-container h1{
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: 100;
  color:#16bfee;
}

.text{
  font-size: 13px;
  color: #ffffff;
  line-height: 30px;
  text-align: justify;
  margin-bottom: 40px;
}

.skills{
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  font-size: 13px;
}

@media screen and (max-width:1200px){
  .inner-container{
      padding: 80px;
  }
}

@media screen and (max-width:1000px){
  .about-section{
      background-size: 100%;
      padding: 100px 40px;
  }
  .inner-container{
      width: 100%;
  }
}

@media screen and (max-width:600px){
  .about-section{
      padding: 0;
  }
  .inner-container{
      padding: 60px;
  }
}
