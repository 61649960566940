.showhide{
  display: none !important;
}

.navbar-light .navbar-toggler-icon {

  background-image:url("menu.svg")!important;

}
.navbar-light .navbar-toggler {
  border-color:rgb(0 0 0 / 0%) !important;
}
.centerbuttom{
  text-align: center !important;
}
.navbar { 
  width: 100%;
  max-width: 100%;
  min-height: 0.5px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1e1f2698;
  transition: all 0.2s ease;
  p {
    text-align: center;
    color: rgb(255, 255, 255);
    text-decoration: none;
    position: relative;
    &:hover{
      color: #16bfee;
    }
  }
  // p:hover{
  //   background-color: black;

  // }
  // box-shadow: 2px 2px 10px rgba(0, 0, 255, 0.158);
}
.logo {
  img {
    height: auto;
    max-width: 35vh;
    padding-left: 30px;
    cursor: pointer;
    margin-top: -50px;
    margin-bottom: -50px;
  }
}
.manu-padding {
  padding-top: 15px;
 
}

/*effect-underline*/
.effect-underline:after {
	content: '';
  position: absolute;
  left: 0;
  display: inline-block;
  height: 1em;
  width: 100%;
  border-bottom: 1px solid;
  margin-top: 10px;
  opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: scale(0,1);
	transform: scale(0,1);
}

.effect-underline:hover:after {
  opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}



