hr{
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
hr.rainbow{
    height: 1px;
    background: white;
    background: -webkit-gradient(linear, left top, right bottom, color-stop(0%,#e7e7e7), color-stop(25%,#ffff00), color-stop(50%,#00ff00), color-stop(75%,#00ffff), color-stop(100%,#0000ff));
    background: -webkit-linear-gradient(-45deg, #ff0000 0%,#ffff00 25%,#00ff00 50%,#00ffff 75%,#0000ff 100%);
    background: -moz-linear-gradient(-45deg, #ff0000 0%, #ffff00 25%, #00ff00 50%, #00ffff 75%, #0000ff 100%);
    background: -o-linear-gradient(-45deg, #ff0000 0%,#ffff00 25%,#00ff00 50%,#00ffff 75%,#0000ff 100%);
    background: -ms-linear-gradient(-45deg, #ff0000 0%,#ffff00 25%,#00ff00 50%,#00ffff 75%,#0000ff 100%);
    background: linear-gradient((-45deg, #00ff00 20%, #00ffff 60%, #0000ff 70%));
}