body {
  font-family: "Kanit", sans-serif !important;

}

//img
.img-cotainer {
  background-image: url("Homeimg.jpg");
  height: 70vh;
  background-size: cover;
  background-position: center;
  background-color: rgba(0, 0, 0, 0);
  position: relative;
  z-index: -1;
  
}
//video
video{
  width: 100%;
  position: absolute;
  z-index: -1;
}
.video-cotainer {
  height: 70vh;
  background-color: rgba(0, 0, 0, 0);
  
}
//welcome
.welcome{

  height: 100%;
  width: 100%;
text-align: center;
align-items: center;
padding-top: 25vh;
  h1{
    padding-top: 2vh;
    padding-bottom: 2vh;
    font-family: "Kanit", sans-serif !important;
    font-size: 5vw;
    color: #16bfee;
    font-weight: 500;
    background-color: #1e1f2698;
    margin-left: 30vw;
    margin-right: 30vw;
  }
  h2{
    
    
    font-family: "Kanit", sans-serif !important;
    font-size: 5vw;
    color: white;
    font-weight: 300;
  }
}

//fontbutton
.front {
  height: auto;
  max-width: 100%;
  background-color: #1e1f26;
  h1 {
    font-weight: 1;
    font-size: 50px;
    text-align: center;
    padding-bottom: 1px;
    color: #16bfee;
  }

  p {
    text-align: center;
    margin-top: 0;
    color: #fff;
    font-weight: 1;
  }
}
.padding {
  padding-top: 10px;
  padding-left: 30px;
}
.container {
  padding-top: 28px;
  padding-bottom: 28px;
}
@media screen and (max-width: 767px) {
  .video-cotainer{
    display: none;
  }
  .front{
    h1{
      font-size: 20px;
    }
    p{
      font-size: 9px;
    }
  }
  .img-cotainer {

    height: 50vh;  
  }

}