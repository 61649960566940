.Customer-text {
    padding: 70px 20px 5px 20px;
    background-color: #1e1f26;
  
    h1 {
      padding-top: 10px;
      font-weight: 1;
      font-size: 50px;
      text-align: center;
      padding-bottom: 1px;
      color: #16bfee;
    }
    p {
      text-align: center;
      margin-top: 0;
      color: #fff;
      font-weight: 1;
    }
  }
  @media screen and (max-width: 767px) {
    .Customer-text{
      
      h1{
        font-size: 20px;
      }
      p{
        font-size: 9px;
      }
    }
  
  }